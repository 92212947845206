import React, { Suspense } from 'react';
import '../App.css';

const Trending = React.lazy(() => import ('../components/Trending'));



const About = () => {
  return (
   <><div class="">
      <div class="text-left abtdiv card">
          <h1 class="card-title"> What is Lawyerz </h1>
          <div class="card">
          <h6><p class="abt">
              Lawyerz is a platform with Lawyer search engine,knowledge graph, discovery service, recommendation service, question and answer service, law bot / virtual assistant using text mining and machine learning, legal jobs source, and legal professional network. 
              Lawyerz helps you "Find the right lawyer" without any buyers remorse or fear of dissatisfaction. Lawyers, attorneys, solicitors, litigators, senior counsel and law firms can bank on getting the right client rather than time wasters. 
              Its a win win for all parties involved as we help in reducing churn and noise.   
              <br/><br/>            
              Lawyerz provides search and discovery of detailed data with relevance and knowledge graph 
              about millions of lawyers with recent ratings, reviews, practice areas, speciality, phone numbers, location with address and much more.
              <br/><br/>
              Discover the right match for your legal issues, legal needs and cases with relevant data and recommendations. 
              Search over millions of lawyers across the world and get the best lawyers, solicitors, attorneys, litigators, barristers, defenders, legal aid for your needs. 
              <br/><br/>
              Find all types of lawyers - personal injury lawyer, accident attorney, divorce lawyer, custody lawyer, immigration lawyer,workers comp lawyer, wrongful termination lawyer, 
              business lawyer, bankruptcy attorney, DUI and DWI lawyer, asbestos lawyer, insurance lawyer, medical negligence lawyer, real estate lawyer, estate lawyer, intellectual property lawyers, affordable lawyers, no win no fee solicitors, conveyancing solicitors,patent attorney, startup lawyers, barristers, tax attorneys, medical malpractice lawyers, 24 hr lawyers, debt lawyers, immigration attorneys, estate planning attorneys, criminal defense attorneys, pro bono and others.
          </p></h6>
          <h6><p> For more information and queries or to send a message <a href="/contact">contact us here</a> </p></h6></div>
          <div class="container-fluid trtags text-left">
              <Suspense fallback={<div>Loading...</div>}>
                  <Trending count={10}></Trending>
              </Suspense>
          </div>
      </div>
          
     </div></>
    );
}
  
export { About };
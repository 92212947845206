import "@babel/polyfill";

import React from "react";

import { LawNavbar }  from './components/LawNavbar';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import GetEncodedQry from './GetEncodedQry';
//import { Home } from './pages';
import { Search } from './pages/search';
import { BrowseList } from './pages/browse';
import { About } from './pages/about';
//import { Cities } from './pages/cities';

import {ContactForm} from './pages/contactform';
import locationData from './components/law-loc-lang-min.json';

const phnums = [
    {
      "Name": "Car Accident Lawyer",
      "Phone": ['1-877-561-8156']
    },
    {
      "Name": "Personal Injury Attorney",
      "Phone": ['1-877-587-5403']
    },
    {
      "Name": "Workers Compensation Attorney",
      "Phone": ['1-877-414-4615']
    },
    {
      "Name": "Social Security Disability Lawyers",
      "Phone": ['1-877-566-2837']
    },
    {
      "Name": "Bankruptcy Lawyer",
      "Phone": ['1-877-859-2588']
    },
    {
      "Name": "Divorce Attorneys",
      "Phone": ['1-877-564-1428']
    },
    {
      "Name": "DUI Attorney",
      "Phone": ['1-877-328-2785']
    },
    {
      "Name": "Criminal Attorneys",
      "Phone": ['1-877-684-7085']
    },
    {
      "Name": "Tax Debt Relief",
      "Phone": ['1-877-915-0014','1-888-820-2754']
    }
  ];
const queryParams = new URLSearchParams(window.location.search);
 const qid = queryParams.get('qid');
 var keyword = queryParams.get('q');
 var loc = queryParams.get('loc')
 var lan = queryParams.get('lang');
 var loc_name = queryParams.get('lname');
 var page = queryParams.get('page');
 var callsrch = 0;
 var fqry = '';
 var pt = '';
 const ishome = window.location.pathname === "/" ? 1 : 0;
 //var locfound = 1;
 //var gotLoc = false;

//  var ht =  phnums.map(ph => {
//     let nm =  '<div class="phitemdiv"><div class="phname"><span class="fa fa-legal">&nbsp;</span>'+ ph.Name + '</div>';
//     //alert(nm);
//     let phnlst = (ph.Phone.map(pn => {
//         return '<div class="phnum"><span class="fa fa-phone"><a class="pnl" href="tel:' 
//      + pn.toString() + '">' + pn.toString() + '</a></span></div>';
//     }));
//     //alert(phnlst);
//     //alert(nm.concat(phnlst));
//     phnlst = phnlst.join('');
//     //alert(nm.concat(phnlst));
//     return (nm.concat(phnlst,'</div>'));
//   }).join('');
// //{`(ht.join(''))}
//  //var phSpan =  <span>{`${ht}`}</span>;
// const phtm = <>{`${ht}`}</>


 if (keyword !=null && loc!=null){
  //formattedQry = id.value + "/" + id.value + "-" + document.getElementById("loc")+ "-"+ document.getElementById("lang");
  callsrch = 1;
  //if(!parseInt(page)) page = 1;
  if(!page) page = 1;
}
else { callsrch = 0; 
    page = 1; 
    keyword = "lawyers";
   // if(loc==null || loc == '') 
   // { loc = "9073451"; lan = "en"; loc_name = "San Francisco Bay Area"; }
   // else 
    //if(lan==null || lan == '') lan = "en";
    //if(loc_name==null || loc_name == '') loc_name = "San Francisco Bay Area";

   // loc_name="San Francisco Bay Area";
    //pt = "lawyers in " + loc_name + " - Lawyerz Search "; 
    //fqry = 'lawyers/lawyers-' + loc + '-' + lan;
    }
if (callsrch == 1) { 
    fqry = GetEncodedQry(keyword, loc, lan);
    pt = keyword + " in " + loc_name + " - Lawyerz Search";
}

class App extends React.Component {
     constructor(props) {
        super(props);
  
        this.state = {
            locobj: null,
            gotLoc: false,
            locfound: null
        };
    }

    componentDidMount() {
        if (callsrch === 0) {
  
        try {
  var data;
 // alert('came..');

        fetch('https://lawyerz.com/location')
         .then(response =>response.json())
         .then((cfdata) => {
          this.setState({locobj:cfdata});
          let query = cfdata.cf.city ? cfdata.cf.city.toLowerCase() : '';
          let reg = cfdata.cf.region ? cfdata.cf.region.toLowerCase() : '';
          query = query.concat(",", reg);
         // alert(query);
          if(query!='')
          {
            const filterItems = 
            locationData.find(item => item.full_name.toLowerCase().indexOf(query) == 0);
            data = filterItems;
            //alert(data.full_name);

            if(data == null || data == undefined || data.length == 0)
            { 
               loc = "9073451"; lan = "en"; loc_name = "San Francisco Bay Area"; 
               fqry = GetEncodedQry(keyword, loc, lan);
               this.setState({gotLoc: true});
               this.setState({locfound: 0});
               //alert(this.state.locfound);
            }
            else {
              pt = "lawyers in " + data.full_name + " - Lawyerz Search ";
              loc = data.id;
              lan = data.language_code;
              loc_name = data.full_name;
              fqry = GetEncodedQry(keyword, loc, lan);
              //alert(fqry);
              this.setState({locfound: 1});
              this.setState({gotLoc: true});
              }
           }
          else {
            // rtxt = JSON.stringify(data, null, 2)
            //data = null;
           
              pt = "lawyers in " + data.full_name + " - Lawyerz Search ";
              loc = data.id;
              lan = data.language_code;
              loc_name = data.full_name;
              fqry = GetEncodedQry(keyword, loc, lan);
              //alert(fqry);
              this.setState({locfound: 1});
              this.setState({gotLoc: true});
            }
        

         // fetch('https://getlocation.lawyerz.workers.dev/', {"Access-Control-Allow-Origin": "*",
         //   "Access-Control-Allow-Methods": "GET,OPTIONS"})
         // .then(response => response.json())
         // .then((data) => {
            //this.setState({locobj:data[0]});
            //alert(data[0]);
            
            
            // gotLoc = true;
//alert(gotLoc);
          });
          }
          catch (e) { 
            //default when exception
            loc = "9073451"; lan = "en"; loc_name = "San Francisco Bay Area"; 
            fqry = GetEncodedQry(keyword, loc, lan);
            this.setState({gotLoc: true});
            this.setState({locfound: 0});
            //alert(this.state.locfound);
            }
        }
         // else {
              
              //gotLoc = true;
          //}
         if (callsrch === 1) { fqry = GetEncodedQry(keyword, loc, lan);
            pt = keyword + " in " + loc_name + " - Lawyerz Search";
            this.setState({gotLoc: true});
            this.setState({locfound: 1}); }

    }
   
    render() {
        
        return (
            <div>
            <div class="topmast"></div>
<div>
             {this.state.gotLoc === false && ishome ? <div id="preload" class="container card ver-hori-center">
                    <div class="csslogo"></div>
                    <br/>
                    <h5 class="card-title placeholder-glow">
        <span class="placeholder col-8"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-10"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-12"></span>
      </p>
      </div> : ''}
</div>
 {this.state.gotLoc === true && fqry != '' && this.state.locfound != null &&
  <div>
              {/* came.. {fqry}  {this.state.gotLoc} {this.state.locfound} */}
                <LawNavbar loc={loc} lan={lan} fqry={fqry} keyword={keyword} qid={qid} loc_name={loc_name} page={page}/>
                {/* <div class="ph-outer"> */}
               
        
        <div class="text-center wldiv"><span class="wlmsg">
                    <span class="fa fa-rocket rounded bg-warning text-dark"><span class="nmsg">New</span></span>
                    <span> Get me a Lawyer now, <a class="wlmsg-link" href="https://247.lawyerz.com" target="_blank" rel="noopener noreferrer"><span class="bmsg">24/7 Lawyer Helpline</span></a> - Talk to a lawyer and get instant help (US Only)&nbsp;
                         <a class="wlmsg-link" href="https://247.lawyerz.com" target="_blank" rel="noopener noreferrer"><span class="text-warning fa fa-phone"></span><span class="nmsg text-warning">Call Here</span></a>
                    </span>
                    </span></div>
                 {/* {'<div id="24hrdiv" class="container-fluid flex phdiv">' + {phtm} + '</div>' } */}
        <div id="24hrdiv" class="container-fluid flex phdiv">
            { phnums.map((ph) => (<div class="phitemdiv">
                        <div class="phname"><span class="fa fa-legal">&nbsp;</span>{ph.Name}</div>
                         {ph.Phone.map((pn) => (<div class="phnum"><span class="fa fa-phone"><a class="pnl" href={"tel:" + pn}>{pn}</a></span></div>))}</div>
                 )) } </div> 
        
              <div> <><Router>
                <Routes>
                    <Route exact path='/' element={<Search pt={pt} loc={loc} lan={lan} fqry={fqry} keyword={keyword} qid={qid} loc_name={loc_name} page={page} callsrch={callsrch} locfound={this.state.locfound} />} />
                    <Route path='/search' element={<Search pt={pt} loc={loc} lan={lan} fqry={fqry} keyword={keyword} qid={qid} loc_name={loc_name} page={page} callsrch={callsrch} locfound={this.state.locfound}/>} />
                    <Route path='/browse' element={<BrowseList loc={loc} lan={lan} fqry={fqry} qid={qid} loc_name={loc_name} page={page} />} />
                    {/* <Route path='/cities'  element={<Cities/>}/> */}
                    <Route path='/about'  element={<About/>}/>
                    <Route path='/247-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24-7-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24hr-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24-hr-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/247-lawyer' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24hr-lawyer' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24-hr-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/contact' element={<ContactForm/>}/>

                    <Route path='*' element={<Navigate to='/' />} />

                    {/* <Route path='*' element={<Home />} /> */}

                </Routes>
            </Router></>  </div> 
            </div> }
  
          { this.state.gotLoc === true && fqry != '' && this.state.locfound != null &&  
            <div id="footer" class="ftrimgdiv">
                    <div class="ftrdiv">
      <a href="/" class="nav-link"> 
      <img class="btmimg" loading="lazy" decoding="async" src="https://d1u92gj73ecrol.cloudfront.net/lawyerz-logo-wide-100.png" alt="Lawyerz"/></a>
      <a href="https://247.lawyerz.com" class="nav-link">
      <div class="ftrnavitem"><span class="fa fa-phone"></span>24/7 Lawyer Helpline</div></a>
      <a href="https://lawyerz.com" class="nav-link">
      <div class="ftrnavitem"><span class="fa fa-home"></span>Lawyerz</div></a>
      <a href="https://lawyerz.com/search" class="nav-link"><div class="ftrnavitem"><span class="fa fa-search"></span>Search</div></a>
      <a href="https://lawyerz.com/browse" class="nav-link"><div class="ftrnavitem"><span class="fa fa-list"></span>Lawyer Directory</div></a>
      <a href="https://lawyerz.com/cities"  class="nav-link"><div class="ftrnavitem"><i class="fa fa-building"></i> Lawyers by City</div></a>

      <a href="https://lawyerz.com/about" class="nav-link"><div class="ftrnavitem"><span class="fa fa-info-circle"></span></div></a>
      <a href="https://lawyerz.com/contact" class="nav-link"><div class="ftrnavitem"><span class="fa fa-address-book"></span></div></a>

      </div></div> }
            
            </div>
                    
        );
    }
}

export { App }; 
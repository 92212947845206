import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, { Suspense } from 'react';
const Spinner = React.lazy(() => import ('./spinner'));



const Locations = React.lazy(() => import ('../components/Locations'));



//import NavDropdown from 'react-bootstrap/NavDropdown';

export function LawNavbar(props) {


//   const queryParams = new URLSearchParams(window.location.search);
//   const id = queryParams.get('qid');
//   const keyword = queryParams.get('q');
//   const loc = queryParams.get('loc')
//   const lan = queryParams.get('lang');
//   const loc_name = queryParams.get('lname');
//   var callsrch =0;
//   var fqry = '';
//   if (keyword !=null && loc!=null){
//    //formattedQry = id.value + "/" + id.value + "-" + document.getElementById("loc")+ "-"+ document.getElementById("lang");
//    callsrch = 1;
//  }
//  else { callsrch = 0;  }

//  let qrySel = id;
//  let qryLoc = loc;

  return (
    <Navbar bg="warning" variant="light" className='nopad navmain'>
      <Container>
        <Navbar.Brand href="/"><div class="imgdiv">
          <img class="img-logo" loading="lazy" src="https://d1u92gj73ecrol.cloudfront.net/logo.png"></img></div></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar id="basic-navbar-nav" className='block'>
          <Nav className="me-auto">
            <Nav.Link href={`/search?q=${props.keyword}&qid=${props.qid}&loc=${props.loc}&lname=${props.loc_name}&lang=${props.lan}`}><div class="navitem"><span class="fa fa-search"></span> Search</div></Nav.Link>
            <Nav.Link href='https://247.lawyerz.com' target="_blank" rel="noopener noreferrer" ><div class="navitem"><span class="fa fa-phone"></span> 24/7 Lawyer Helpline</div></Nav.Link>
            <Nav.Link href={`/browse?loc=${props.loc}&lname=${props.loc_name}&lang=${props.lan}`}><div class="navitem"><span class="fa fa-list"></span>Lawyer Directory</div></Nav.Link>
            <Nav.Link href="/cities"><div class="navitem"><i class="fa fa-building"></i> Lawyers by City</div></Nav.Link>
            <Nav.Link href="/about"><div class="navitem"><span class="fa fa-info-circle"></span>About</div></Nav.Link>


            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav class="sbox-out">
            <Nav class="sbox-nav"><div>
               <Suspense fallback={<div>Loading...</div>}> <Locations selVal={props.loc} /></Suspense> 
            </div></Nav>
          </Nav> 
        </Navbar>
      </Container>
          {/* <div class="sbox-out">
            <Nav class="sbox-nav"><div>
               <Suspense fallback={<div>Loading...</div>}> <Locations selVal={props.loc} /></Suspense> 
            </div></Nav>
            <Nav.Link href="/about"><div class="navitem">About</div></Nav.Link>
          </div> */}
    </Navbar>
  )
}
export default LawNavbar;

//import { paginationClasses } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
//import Locations from '../components/Locations';
//import Queries from '../components/Queries';
//import Trending from '../components/Trending';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useParams
// } from "react-router-dom";
//import GetEncodedQry from '../GetEncodedQry';
//import GetLocation from '../GetLocation';
//const Locations = React.lazy(() => import ('../components/Locations'));
const Queries = React.lazy(() => import ('../components/Queries'));
const Trending = React.lazy(() => import ('../components/Trending'));
const ApiReq = React.lazy(() => import ('../GetRequest'));
//const Spinner = React.lazy(() => import ('../components/spinner'));

// const SetName = React.lazy(() => import ('../components/useDocumentTitle'));

var locobj = '';


const Search = (props) => {
 //Sample address: http://localhost:3000/?id=55&name=test
//  const queryParams = new URLSearchParams(window.location.search);
//  const id = queryParams.get('qid');
//  var keyword = queryParams.get('q');
//  var loc = queryParams.get('loc')
//  var lan = queryParams.get('lang');
//  var loc_name = queryParams.get('lname');
//  var page = queryParams.get('page');
//  var callsrch =0;
//  var fqry = '';
//  if (keyword !=null && loc!=null){
//   //formattedQry = id.value + "/" + id.value + "-" + document.getElementById("loc")+ "-"+ document.getElementById("lang");
//   callsrch = 1;
//   if(!parseInt(page)) page = 1;

// }
// else { callsrch = 0; page = 1; }

// if (callsrch == 1)
// {
//  if (keyword == 'divorce attorney')
//  {
//   fqry = encodeURI("divorce lawyers/divorce lawyers-" + loc + "-" + lan);
//  }
//  else if (keyword == 'personal injury attorney')
//  {
//   fqry = encodeURI("personal injury lawyer/personal injury lawyer-" + loc + "-" + lan);
//  }
//  else if (keyword == 'tax lawyer')
//  { fqry = encodeURI("tax attorney/tax attorney-" + loc + "-" + lan); }
//  else if (keyword == 'immigration attorney') // wrong temp name
//  { fqry = encodeURI("immigration lawyers/immigration lawyers-" + loc + "-" + lan); }
//  else if (keyword == 'civil law attorney') // wrong temp name
//  { fqry = encodeURI("civil lawyers/civil lawyers-" + loc + "-" + lan); }
//  else if (keyword == 'family law attorney') // wrong temp name
//  { fqry = encodeURI("family lawyers/family lawyers-" + loc + "-" + lan); }
//  else if (keyword == 'real estate attorney') // wrong temp name
//  { fqry = encodeURI("real estate lawyers/real estate lawyers-" + loc + "-" + lan); }
//  else if (keyword == 'criminal justice attorney') // wrong temp name
//  { fqry = encodeURI("criminal defense attorney/criminal defense attorney-" + loc + "-" + lan); }
//  else if (keyword == '$500 bankruptcy attorney') // wrong temp name
//  { fqry = encodeURI("500 bankruptcy attorney/500 bankruptcy attorney-" + loc + "-" + lan); }
//  else if (keyword == 'slip and fall attorney') // wrong temp name
//  { fqry = encodeURI("personal injury lawyer/personal injury lawyer-" + loc + "-" + lan); }

// else { fqry = encodeURI(keyword + "/" + keyword + "-" + loc + "-" + lan); }

//fqry = GetEncodedQry(keyword, loc, lan);

//}
 //console.log(id, name, type); // 55 test null
//  var qrySel = JSON.stringify('{id:' + id + ' keyword:' + keyword + '}');
//  var qryLoc = JSON.stringify('{id:' + loc + '}');

// let a = `{"courses_dept": "${dept}", "courses_averge": ${avg} }`;
// let c = {"courses_dept": dept, "courses_averge": avg}
// JSON.stringify(c)

//let qrySel = `{"id": "${id}", "keyword": "${keyword}" }`;
//let qryLoc = `{"id": "${loc}"}`;
// let qrySel = id;
// let qryLoc = loc;
// var pt = '';
// var locobj = '';
// //const[locobj, setLoc] = useState(null);
// if (callsrch == 0) {
  
//   keyword = "lawyers";

// fetch('https://getlocation.lawyerz.workers.dev/', {"Access-Control-Allow-Origin": "*",
//  "Access-Control-Allow-Methods": "GET,OPTIONS"})
// .then(response => response.json())
// .then((data) => {
//   locobj = data[0];
//   alert(locobj.id);
//   if (locobj != null) 
//   {
//    pt = "lawyers in " + locobj.full_name + " - Lawyerz Search ";
//    loc = locobj.id;
//    lan = locobj.language_code;
//    fqry = GetEncodedQry(keyword, loc, lan);
//    alert(fqry);
//    }
//   else { 
//     pt = "lawyers in San Francisco Bay Area - Lawyerz Search "; 
//     fqry = 'lawyers/lawyers-9073451-en';
//     }
//   })
// }
// else {
//   pt = keyword + " in " + loc_name + " - Lawyerz Search";
// }

//alert('src' + props.locfound);
useEffect(() => {
  
  document.title = props.pt;

});

  return (
    <div class="topdiv">
   {/* {callsrch ==1 && */}
    <div>
          {/* <h2 class="text-center hdr">Find the Right Lawyer.</h2> */}
          <div class="mtop"></div>
          <div class="container-fluid sboxdiv">
            <div class="sbox">
              <Suspense fallback={<div>Loading...</div>}>
                <Queries selVal={props.qid} />
              </Suspense>
            </div>
            <button type="submit" class="btn btn-primary btn-lg sbtn"><span class="fa fa-search">&nbsp;</span>Search</button>
          </div>
          <div class="container-fluid trtags">
            <Suspense fallback={<div>Loading...</div>}>
              <Trending></Trending>
            </Suspense>
          </div>
          <div class="rsdiv">
            <h3 className="p-3"> Search Results for "{props.keyword}" in {props.loc_name} {props.locfound === 0 ? '(Default)' : ''} </h3>
            <Suspense fallback={<div>Loading..</div>}>
              <ApiReq formattedQry={props.fqry} page={props.page} query={props.keyword} qid={props.qid} />
            </Suspense>
          </div>
          <div class="container-fluid trtags">
            <Suspense fallback={<div>Loading...</div>}>
              <Trending count={8}></Trending>
            </Suspense>
          </div>
        </div>

   {/* } */}
   {/* {callsrch ==0 &&
    <div>
          <h2 class="text-center hdr">Find the Right Lawyer.</h2>
          <div class="container-fluid sboxdiv">
            <div class="sbox"> <Suspense fallback={<div>Loading...</div>}>
              <Queries /> </Suspense> </div>
            <button type="submit" class="btn btn-primary btn-lg">Search</button>
          </div>
          <div class="container-fluid trtags">
            <Suspense fallback={<div>Loading...</div>}> <Trending></Trending></Suspense>
          </div>
          <div class="rsdiv">
            <h3 className="p-3 text-left">Showing Results for "lawyers" in San Francisco Bay Area (Default) </h3>
            <Suspense fallback={<div>Loading..</div>}>
              <ApiReq formattedQry='lawyers/lawyers-9073451-en' />
            </Suspense>
          </div></div>
          } */}
    
</div>
  );
}
  
export { Search };
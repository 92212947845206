import React, { Suspense } from 'react';
//import Locations from '../components/Locations';
//import Queries from '../components/Queries';
//import Trending from '../components/Trending';

import { useEffect } from "react";

import GetEncodedQry from '../GetEncodedQry';

//const Spinner = React.lazy(() => import ('../components/spinner'));


//const Locations = React.lazy(() => import ('../components/Locations'));
const Links = React.lazy(() => import ('../components/keywordlinks'));
const ApiReq = React.lazy(() => import ('../GetRequest'));
//const SetName = React.lazy(() => import ('../components/useDocumentTitle'));

const BrowseList = (props) => {
 //Sample address: http://localhost:3000/?id=55&name=test
 const queryParams = new URLSearchParams(window.location.search);
 const id = queryParams.get('qid');
 const keyword = queryParams.get('q');
 //const loc = queryParams.get('loc')
 //const lan = queryParams.get('lang');
 //const loc_name = queryParams.get('lname');
 //var page = queryParams.get('page');

 var callsrch =0;
 //var fqry = '';
 //alert(keyword);
 if (keyword !=null && props.loc!=null){
  //formattedQry = id.value + "/" + id.value + "-" + document.getElementById("loc")+ "-"+ document.getElementById("lang");
  callsrch = 1;
  //if(!parseInt(page)) page = 1;
}
else { callsrch = 0; } //props.page = 1;}


var pt = '';
if (callsrch == 0) {
pt = "Lawyer listings by Practice Area - Lawyerz Search ";
}
else {
  pt = keyword + " listings in " + props.loc_name + " - Lawyerz Search";
}

useEffect(() => {
  document.title = pt;
});

return (
    <div class="topdiv">
   {callsrch ==1 &&
    <div>
      <h2 class="text-center">Browse Lawyers by practice area or speciality</h2>
      <div class="container-fluid trtags">
          <Suspense fallback={<div>Loading...</div>}> 
            <Links count={4} />
        </Suspense>
     </div>
      <div class="rsdiv">
      <h3 className="p-3"> Listings for "{keyword}" in {props.loc_name} </h3>
          <Suspense fallback={<div>Loading..</div>}>
                <ApiReq formattedQry = {props.fqry} page={props.page} query={keyword} qid={props.qid}/>   
          </Suspense>
       </div>
       <div class="container-fluid trtags">
          <Suspense fallback={<div>Loading...</div>}> 
            <Links count={8} />
        </Suspense>
     </div>
    </div>

   }
   {callsrch ==0 &&
    <div class="">
    <h2 class="text-center">Browse Lawyers by practice area or speciality</h2>
    {/* <div class="container-fluid sboxdiv">
      <div class="sbox"><Suspense fallback={<div>Loading...</div>}> <Locations /> </Suspense></div>
    </div> */}
    <div class="container-fluid text-center">
      <Suspense fallback={<div>Loading..</div>}>
        <Links />
      </Suspense>
    </div>
    
    <input type="hidden" id="page" name="page" value="1"></input>

    </div>
    }
    </div>
  );
}
  
export { BrowseList };
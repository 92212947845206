export default function GetEncodedQry(keyword, loc, lan) 
{

  var fqry = ''
//    var keyword = props.keyword;
//    var loc = props.loc;
//    var lan = props.lan;
  if (keyword == 'divorce attorney')
  {
   fqry = encodeURI("divorce lawyers/divorce lawyers-" + loc + "-" + lan);
  }
  else if (keyword == 'personal injury attorney')
  {
   fqry = encodeURI("personal injury lawyer/personal injury lawyer-" + loc + "-" + lan);
  }
  else if (keyword == 'tax lawyer')
  { fqry = encodeURI("tax attorney/tax attorney-" + loc + "-" + lan); }
  else if (keyword == 'immigration attorney') // wrong temp name
  { fqry = encodeURI("immigration lawyers/immigration lawyers-" + loc + "-" + lan); }
  else if (keyword == 'civil law attorney') // wrong temp name
  { fqry = encodeURI("civil lawyers/civil lawyers-" + loc + "-" + lan); }
  else if (keyword == 'family law attorney') // wrong temp name
  { fqry = encodeURI("family lawyers/family lawyers-" + loc + "-" + lan); }
  else if (keyword == 'real estate attorney') // wrong temp name
  { fqry = encodeURI("real estate lawyers/real estate lawyers-" + loc + "-" + lan); }
  else if (keyword == 'criminal justice attorney') // wrong temp name
  { fqry = encodeURI("criminal defense attorney/criminal defense attorney-" + loc + "-" + lan); }
  else if (keyword == '$500 bankruptcy attorney') // wrong temp name
  { fqry = encodeURI("500 bankruptcy attorney/500 bankruptcy attorney-" + loc + "-" + lan); }
  else if (keyword == 'slip and fall attorney') // wrong temp name
  { fqry = encodeURI("personal injury lawyer/personal injury lawyer-" + loc + "-" + lan); }
 
 else { fqry = encodeURI(keyword + "/" + keyword + "-" + loc + "-" + lan); }
 
 //alert(fqry);
 return fqry;
}
import React from "react";

const ContactForm = () => {

    return (

        <div class="text-center">

        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfAuVq3SRGs8aIKO4cJON7DfP-_f6cghWoyfPfcV8reiBh4pg/viewform?embedded=true" 
        width="100%" height="960" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    );
}

export { ContactForm };